<template>
  <div>
    <!-- <div class="mt-4">
      <div class="relative -mt-3" uk-slider="finite: true">
        <div class="uk-slider-container px-1 py-3">
          <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
            <li>
              <div class="rounded-md overflow-hidden relative w-full h-36">
                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                </div>
                <img src="https://static.bcdcnt.net/assets/images/bcdcnt/nhac-tien-chien.jpg" class="absolute w-full h-full object-cover" alt="">
                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc tiền chiến</div>
              </div>
            </li>
            <li>
              <div class="rounded-md overflow-hidden relative w-full h-36">
                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                </div>
                <img src="assets/images/bcdcnt/nhac-cach-mang.jpg" class="absolute w-full h-full object-cover" alt="">
                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc cách mạng</div>
              </div>
            </li>
            <li>
              <div class="rounded-md overflow-hidden relative w-full h-36">
                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                </div>
                <img src="assets/images/bcdcnt/nhac-nhe.jpg" class="absolute w-full h-full object-cover" alt="">
                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc nhẹ</div>
              </div>
            </li>
            <li>
              <div class="rounded-md overflow-hidden relative w-full h-36">
                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                </div>
                <img src="assets/images/bcdcnt/nhac-thieu-nhi.png" class="absolute w-full h-full object-cover"
                     alt="">
                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc thiếu nhi</div>
              </div>
            </li>
            <li>
              <div class="rounded-md overflow-hidden relative w-full h-36">
                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                </div>
                <img src="assets/images/bcdcnt/nhac-nuoc-ngoai.png" class="absolute w-full h-full object-cover"
                     alt="">
                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc nước ngoài</div>
              </div>
            </li>
            <li>
              <div class="rounded-md overflow-hidden relative w-full h-36">
                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                </div>
                <img src="assets/images/bcdcnt/nhac-tru-tinh.jpg" class="absolute w-full h-full object-cover" alt="">
                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc trữ tình</div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div> -->
    <div class="lg:flex lg:space-x-10" v-if="songs && songs.data && songs.data.length">
      <div class="w-full">
        <div class="flex items-center justify-between border-b pb-3 relative">
          <div>
            <div v-if="!title"></div>
            <h2 v-if="title" class="text-xl font-semibold">{{ title }}: {{ thousandFormat(songs.paginatorInfo.total) }} bài</h2>
          </div>
          <div class="flex justify-between">
            <nav class="mr-3 relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a href="javascript:void(0)"
                v-on:click="layout = 'list'"
                :class="{'bg-gray-200': layout === 'list'}"
                class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="icon-feather-grid"></span>
              </a>
              <a href="javascript:void(0)"
                v-on:click="layout = 'table'"
                :class="{'bg-gray-200': layout === 'table'}"
                class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="icon-feather-list"></span>
              </a>
            </nav>
            <select class="mr-3 w-auto px-2 float-right" v-model="sort">
              <option value="created_at:DESC">Mới nhất</option>
              <option value="title:ASC">Tên bài</option>
              <option value="views:DESC">Lượt nghe</option>
              <option value="downloads:DESC">Lượt tải</option>
            </select>
            <select class="w-auto px-2 float-right" v-model="vovHQ" v-if="prefix !== 'thanh-vien-hat'">
              <option value="">Tất cả</option>
              <option value="vov-hq">vov-hq</option>
            </select>
          </div>
        </div>

        <div class="card p-3">
          <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4 mt-4" v-if="songs.data.length && layout === 'list'">
            <SongItem v-for="song in songs.data" :song="song" :prefix="prefix" :key="'song' + song.id"/>
          </div>

          <table v-if="songs.data.length && layout === 'table'" class="w-full">
            <thead>
              <tr class="">
                <td class="px-2 py-2 text-emerald-600 font-semibold">Bài hát</td>
                <td class="px-2 py-2 text-emerald-600 font-semibold">Sáng tác</td>
                <td class="px-2 py-2 text-emerald-600 font-semibold">Năm sáng tác</td>
                <td class="px-2 py-2 text-emerald-600 font-semibold">Lượt nghe</td>
                <td class="px-2 py-2 text-emerald-600 font-semibold">Lượt tải</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="song in songs.data" :key="'song' + song.id" class="px-2 py-2 text-emerald-600 font-medium">
                <td class="px-2 py-2 text-emerald-600 font-medium">
                  <router-link class="font-semibold" :title="song.title" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: this.prefix}}">
                    {{ song.title }}
                  </router-link>
                </td>
                <td class="px-2 py-2 text-emerald-600 font-medium">
                  <div class="text-sm text-gray-500 mt-0.5 people-in-song" v-if="song.sheet && song.sheet.composers && song.sheet.composers.data">
                    <PeopleOfSong :people="song.sheet.composers.data" type="composer"/>
                  </div>
                </td>
                <td class="px-2 py-2 text-emerald-600 font-medium">{{ song.sheet && song.sheet.year ? song.sheet.year : "" }}</td>
                <td class="px-2 py-2 text-emerald-600 font-medium" :title="thousandFormat(song.views) + ' lượt nghe'">{{ thousandFormat(song.views) }}</td>
                <td class="px-2 py-2 text-emerald-600 font-medium" :title="thousandFormat(song.downloads) + ' lượt tải'">{{ thousandFormat(song.downloads) }}</td>
              </tr>
            </tbody>
          </table>

          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="songs.paginatorInfo.lastPage > 1"
                v-model="page"
                :pages="songs.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadSongs"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import PeopleOfSong from "./PeopleOfSong";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import SongItem from "../SongItem";
import {changePageTitle, thousandFormat, numberFormat} from "../../core/services/utils.service";

export default {
  name: 'Music',
  components: {
    VPagination,
    PeopleOfSong,
    SongItem
  },
  props: {
    prefix: String,
    title: String,
    songs: {
      type: Object,
      default() {
        return {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            lastPage: 0,
            total: 0
          }
        };
      }
    }
  },
  data() {
    return {
      page: 1,
      sort: "created_at:DESC",
      sortColumn: "created_at",
      sortDirection: "DESC",
      layout: "list",
      vovHQ: ""
    }
  },
  methods: {
    loadSongs() {
      let options = {
        page: this.page,
        orderBy: this.getOrderBy()
      };

      if (this.vovHQ === 'vov-hq') {
        options.vovHQ = true;
      }

      this.$emit("loadSongs", options);
    },
    getOrderBy() {
      return [{column: this.sortColumn, order: this.sortDirection}];
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    numberFormat(x) {
      return numberFormat(x);
    }
  },
  mounted() {
    this.loadSongs();
    changePageTitle("Tân nhạc");
  },
  watch: {
    sort: {
      handler(sort) {
        let tmp = sort.split(":");
        this.sortColumn = tmp[0];
        this.sortDirection = tmp[1];
        this.page = 1;
        this.loadSongs();
      }
    },
    vovHQ: {
      handler() {
        this.page = 1;
        this.loadSongs();
      }
    },
    "paginatorInfo.currentPage": {
      handler(page) {
        this.page = page;
      },
      deep: true
    }
  },
}
</script>
